<template>
    <div class="switch-item comment-for " data-type="3" style="display: block;">
    <div class="order-rule"><span>默认时间排序</span></div>
	<ul>
		<li v-for="comment in commentList" :key="comment.id">
			<a class="face">
				<img :src="comment.authorImg" alt="" width="60" height="60">
			</a>
            <div class="info">
                <div style="width:100%;position:relative;height:100%">
                    <div class="title" style="position:absolute;display:inline-block">
                        <span class="nickname">{{comment.authorName}}</span>
                        <a href="javaScript:void(0)" @click="goToArticleDetail(comment.articleId)">
                            <span>评论文章：</span>{{comment.articleTitle}}
                        </a>
                    </div>
                    <div style="position:absolute;display:inline-block;right:0">{{comment.time}}</div>
                </div>  
                <p>{{comment.content}}</p>
            </div>
		</li>
	</ul>
  </div>
</template>

<script>
export default {
    props:{
         commentList:{
             type:Array,
             required:true
         }
    },
    methods:{
        //文章详情
      goToArticleDetail(id){
            this.$router.push({path:'/articleDetail', query: {
                id: id
            }});
      } 
    }
}
</script>

<style scoped>

li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
    border: 0;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
}
.comment-for li {
    padding: 15px 0;
    border-bottom: 1px solid #E6E6E6;
    overflow: hidden;
    font-size: 14px;
}
.comment-for li .face {
    width: 60px;
    height: 60px;
    font-size: 0;
    float: left;
    margin-right: 20px;
}
.comment-for li .face img {
    border-radius: 50%;
}
.comment-for li .info {
    overflow: hidden;
    padding-right: 70px;
}
.comment-for li .title {
    font-size: 14px;
}
.comment-for .info p {
    line-height: 18px;
    padding-top: 10px;
    font-size: 14px;
    color: #31424e;
}
.comment-for li .nickname {
    color: #31424e;
}
.comment-for li .title a {
    font-size: 12px;
    color: #5e6b73;
}
.comment-for li .title a span {
    font-size: 12px;
    color: #9baab6;
}
.order-rule{
    text-align: right;
}
.order-rule span{
    padding-right:70px
}
</style>